<template>
<div class="header">
    <div @click="$router.push({name: 'Reports'})">&lt; Back</div>
</div>
<div class="post-header-offset"></div>
<div class="report_creation">
    <div v-if="!digitization_error" class="canvas" ref="canvasref" @click="$refs.picture.click()">
        <div class="imageref">
            <img :src="format_image" ref="imageref"/>
        </div>
        <div class="tap-to-select-photo">Tap to <span v-if="current_file === null">select</span><span v-else>change</span> photo</div>
    </div>

    <div v-if="digitization_error" class="digitization_error">
        <div>{{ digitization_error }}</div>
        <button @click="$router.go()">OK</button>
    </div>

    <form class="report_creation_form" v-on:submit.prevent="digitize">
        <input type="file" style="display: none;" accept="image/*;capture=camera" v-on:change="fileChange" ref="picture"/>

        <div class="green-banner" v-if="current_time < 1722895200000">
            Now with automatic Rotation and Format detection!
        </div>
        
        <Parameter :options="['5', '12.5', '25', '50']" default_value="25" label="Paper speed (mm/s)" @changed_selection="$event => paper_speed=$event"/>
        <Parameter :options="['2.5', '5', '10', '20']" default_value="10" label="Voltage gain (mm/mV)" @changed_selection="$event => voltage_gain=$event"/>
        
        <div class="button_set" v-if="!digitization_error">
            <button :disabled="digitizing" class="free_digitization_button" v-if="!store.remaining_ecgs()">{{ digitization_button_text }}</button>
            <button :disabled="digitizing" class="premium_digitization_button" v-if="store.remaining_ecgs()">{{ digitization_button_text }}</button>
        </div>
    </form>
</div>

</template>

<script>

import Parameter from "../components/Parameter.vue"
import {store} from "../utils/store.js"
import {api} from "../utils/api.js"

export default {
    components: {Parameter},
    data() {
        return {
            rotation: 0,
            paper_speed: null,
            voltage_gain: null,
            transform: "rotate(0)",
            width: 10,
            height: 10,
            format_image: '/img/formats/6x2x1.svg',
            current_file: null,
            digitization_button_text: "",
            store: store,
            digitizing: false,
            digitization_error: "",
            current_time: new Date().getTime(),
        }
    },
    computed: {
        default_digitization_button_text() {
            if (store.remaining_ecgs()){
                return "Digitize";
            } else {
                return "Trial Digitization";
            }
        }
    },
    methods: {
        fileChange(e) {
            const files = e.target.files
            this.current_file = files[0];
            const url = URL.createObjectURL(this.current_file);
            this.$refs.imageref.setAttribute("src", url)
            this.width = this.$refs.canvasref.clientWidth;
            this.height = this.$refs.canvasref.clientHeight;
            this.rotation = 0;
            this.rotate(0);
        },
        async digitize() {
            if (this.current_file === null) {
                this.$refs.picture.click();
                return;
            }
            this.digitizing = true;
            this.digitization_button_text = "Digitizing..."
            window.setTimeout(() => {this.digitization_button_text = "Digitizing... this is a hard one...";}, 8000);
            let res;
            try {
                res = await api.auth_post(
                    "/digitize",
                    {
                        paper_speed: this.paper_speed,
                        voltage_gain: this.voltage_gain,
                        rotation: this.rotation,
                    },
                    this.current_file,
                )
            } catch (err) {
                this.digitization_error = `Something went wrong, the digitization failed. Please try again later. ${err}`;
            } finally {
                this.digitization_button_text = this.default_digitization_button_text;
                this.digitizing = false;
            }
            if (res.status == 406) {
                this.digitization_error = "Invalid file. Please upload only JPG or PNG files. Other files are not currently supported.";
                return;
            }
            if (res.status !== 200) {
                this.digitization_error = "Something went wrong, the digitization failed. Please try again later.";
                return;
            }

            let j = await res.json()
            
            if (j["possibly_wrong_rotation"]) {
                this.digitization_error = "Incorrect rotation! Please rotate your photo so that the ECG is upright.";
            } else if (j["image_too_small"]) {
                this.digitization_error = "The ECG you photographed is too small. Please take photos of the entire ECG.";
            } else if (j["unclear_grid"]) {
                this.digitization_error = "The ECG could not be found on your photo.";
            } else if (j["unclear_orientation"]) {
                this.digitization_error = "The photo you took is too skewed. Please take photos of upright ECGs aligned to the edges of the photo.";
            } else if (j["grid_too_large"]) {
                this.digitization_error = "The ECG you photographed seems to be unreasonbly large. Please only take pictures of individual regular ECGs.";
            } else if (j["unexpected_leads"]) {
                this.digitization_error = "The format you selected doesn't seem to match the ecg you photographed!";
            } else {
                store.handle_sub(j);
                this.$router.push({
                    name: "Report",
                    params: {
                        report_id: j["report_id"],
                    },
                    query: {
                        report_access_token: j["report_access_token"],
                    }
                })
            }
        }
    },
    mounted() {
        this.digitization_button_text = this.default_digitization_button_text;
    },
}

</script>

<style>

.report_creation {
    width: clamp(30vw, 1200px, 95vmin);
    margin: 0 auto 0 auto;
}

.report_creation_form {
    display: block;
}

.button_set {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.button_set button {
    flex-grow: 1;
    flex-basis: 0;
    line-height: 5vh;
    border-radius: 7px;
    font-weight: bold;
}

.rotation_button {
    color: black;
    background-color: white;
    cursor: pointer;
}

.free_digitization_button {
    color: #ffffff;
    background-color: #0033CC;
    cursor: pointer;
}

.premium_digitization_button {
    color: #F7F8FA;
    background-color: #0033CC;
    cursor: pointer;
}

.green-banner {
    color: #090;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.canvas {
    height: 30vh;
    position: relative;
    background-color: #DCDEE9;
    border-radius: clamp(1vmin, 2vmin, 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.digitization_error {
    height: 30vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.digitization_error * {
    font-weight: bold;
    text-align: center;
    padding: 5px;
}
.digitization_error div {
    color: red;
}

.imageref {
    height: 27vh;
    width: 27vh;
    display: flex;
    justify-content: space-around;

    /* Below is from here: https://stackoverflow.com/questions/14387690/how-can-i-show-only-corner-borders */
    background:
        linear-gradient(to right, #C9CCDE 4px, transparent 4px) 0 0,
        linear-gradient(to right, #C9CCDE 4px, transparent 4px) 0 100%,
        linear-gradient(to left, #C9CCDE 4px, transparent 4px) 100% 0,
        linear-gradient(to left, #C9CCDE 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, #C9CCDE 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, #C9CCDE 4px, transparent 4px) 100% 0,
        linear-gradient(to top, #C9CCDE 4px, transparent 4px) 0 100%,
        linear-gradient(to top, #C9CCDE 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tap-to-select-photo {
    color: #9FA1AB;
    font-weight: bold;
    position: absolute;
    bottom: 1em;
}

.imageref img {
    height: 23vh;
    width: 23vh;
    object-fit: contain;
    max-width: 100%;
    transform: v-bind("transform");
}

.canvas_label {
    position: absolute;
    top: 2vmin;
    left: 0;
    right: 0;
    font-size: min(4vmin, 25px);
    font-weight: bold;
    background-color: rgba(0,0,0,0);
    color: black;
}

.digitization_error button {
    background-color: #0033CC;
    width: min(50vw, 200px);
    color: white;
    margin: 0 auto 0 auto;
    border-radius: 5px;
}

</style>
