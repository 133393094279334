<template>
<div class="header">
    <div @click="$router.push({name: 'Reports'})">&lt; Back</div>
</div>
<div class="post-header-offset"></div>
<div v-if="!store.is_premium()">
    <h2 style="text-align: center;">Unlock Premium with the limited time offer</h2>
    <div class="plans-holder">
        <div class="plan-holder">
            <h2>Premium</h2>
            <p><span class="special-offer">50% launch discount</span></p>
            <p><span class="plan-price">$7.49</span> / month</p>
            <p style="text-decoration: line-through;">$14.99 / month</p>
            <div :disabled="checkout_in_progress" class="plan" @click="checkout">{{ plan_button_text }}</div>
        </div>
        <!-- <div v-if="store.remaining_ecgs()">You still have {{ store.remaining_ecgs() }} ECG{{ store.remaining_ecgs() > 1 ? "s" : "" }} until {{ moment.unix(store.stripe_subscription_end).format("YYYY/MM/DD hh:mm:ss A Z") }}</div> -->
    </div>
</div>
<div v-if="store.is_premium()">
    <div class="current-plan" v-for="sub in subscriptions" v-bind:id="sub['stripe_subscription_id']">
        <div class="premium-title">You are a Premium User</div>
        <div class="label">Price</div>
        <div class="value">{{ sub["price"]/100 }} {{ sub["currency"] }} / {{ sub["interval"] }}</div>

        <div class="label">Next payment</div>
        <div class="value">{{ moment.unix(sub["current_period_end"]).format("MMM DD YYYY") }}</div>

        <span class="cancel_sub"><span @click="cancel">Cancel Subscription</span></span>
    </div>
</div>
<div class="help-text">
    For any questions or feedback, please get in touch with us via <a href="mailto:help@powerfulmedical.com">help@powerfulmedical.com</a>.
</div>
</template>

<script>

import {store} from "../utils/store.js"
import {api} from "../utils/api.js"

import moment from 'moment';

export default {
    data () {
        return {
            store: store,
            subscriptions: [],
            moment: moment,
            checkout_url: "",
            checkout_in_progress: false,
            plan_button_text: "Claim offer",
        }
    },
    methods: {
        async checkout() {
            this.checkout_in_progress = true;
            this.plan_button_text = "checkout in progress...";
            let res = await api.auth_get("/billing/checkout");
            let j = await res.json()
            this.checkout_url = j["checkout"];
            window.location.href = this.checkout_url;
        },

        async cancel() {
            if(confirm("Are you sure you wish to cancel your subscription?")) {
                await api.auth_post("/billing/unsubscribe");
                store.handle_unsub();
                location.reload();
            }
        }
    },
    async mounted() {
        let res = await api.auth_get("/billing/subscriptions");
        this.subscriptions = await res.json()
        if(this.subscriptions.length > 0){
            store.handle_sub(this.subscriptions[0]);
        }
    }
};
</script>
<style>

.plans-holder {
    display: flex;
    justify-content: center;
}

.plan-holder {
    text-align: center;
    padding: 30px min(5vw, 120px) 30px min(5vw, 120px);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 30px #dddddd;
    color: black;
    max-width: vw;
}

.plan-holder h2 {
    margin: 0;
    padding: 0;
}

.plan-holder .special-offer {
    display: inline-block;
    background-color: #E2D6FF;
    border-radius: 5px;
    color: #260477;
    font-weight: bold;
    padding: 0 8px 0 8px;
}

.plan-holder .plan-price {
    font-size: 3em;
    font-weight: bold;
}

.plan {
    background-color: #1130BD;
    color: white;
    width: min(80%, 500px);
    margin: 10px auto 10px auto;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}

.current-plan {
    background-color: white;
    border-radius: 15px;
    margin: 0 auto 0 auto;
    padding: clamp(5vmin, 50px, 10vmin);
    width: min(70vw, 700px);
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
}

.current-plan .label {
    color: #6D7174;
    font-weight: bold;
}

.current-plan .value {
    color: #6D7174;
    border-bottom: 1pt solid #cbcbcb;
    margin-top: clamp(0.5vh, 5px, 3vh);
    padding-bottom: clamp(0.5vh, 5px, 3vh);
    margin-bottom: clamp(2vh, 5px, 3vh);
}

.cancel_sub {
    color: #0033CC;
    display: block;
    text-align: center;
    text-decoration: underline;
    margin: 0 auto 0 auto;
}

.cancel_sub span {
    cursor: pointer;
}

.help-text {
    margin: clamp(0.5vh, 15px, 3vh) auto 0 auto;
    -webkit-user-select: text; /* Safari */
    -ms-user-select: text; /* IE 10 and IE 11 */
    user-select: text; /* Standard syntax */
    text-align: center;
    padding: 0 5vw 0 5vw;
}

.current-plan .premium-title {
    text-align: center;
    font-size: 1.3em;
    margin-bottom: 0.3em;
}

</style>
