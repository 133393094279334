<template>

<h1>Password Reset</h1>

<div class="reset-password-page">
<div v-if="state === 'start'">
    Please wait...
</div>
<div v-if="state === 'failed'">
    {{ error }}
</div>
<div v-if="state === 'reset'">
    <form @submit.prevent="reset_password" class="authentication-form">
        <label>Email</label>
        <input type="text" disabled class="form_input" v-model="email"/>
        <label>Enter new Password</label>
        <input type="password" class="form_input" autocomplete="new-password" required v-model="password" @keypress="() => {red_password_hints=false}"/>
        <label>Enter new Password Again</label>
        <input type="password" class="form_input" autocomplete="new-password" required v-model="password_verify" @keypress="() => {red_password_hints=false}"/>
        <ul :class="{'errors': red_password_hints}">
            <li v-for="err in this.password_errors">{{ err }}</li>
        </ul>
        <button type="submit" class="login_button">
            Save
        </button>
    </form>
</div>
<div v-if="state === 'reset.complete'">
    <p>Password has been reset!</p>
    <p><a class="login_button" href="/">Log in</a></p>
</div>
</div>
</template>
<script>

import {api} from "../../utils/api.js";
import {validation} from "../../utils/validation.js";

export default {
    data() {
        return {
            email: this.$route.query.email,
            token: this.$route.query.token,
            state: "start",
            error: "",
            password: "",
            password_verify: "",
            red_password_hints: false,
        }
    },
    methods: {
        async reset_password() {
            if (this.password_errors.length > 0){
                this.red_password_hints = true;
                return;
            }
            let res = await api.post(
                "/auth/reset_password",
                {
                    "email": this.email,
                    "token": this.token,
                    "password": this.password,
                }
            )
            if (res.status === 200){
                this.state = "reset.complete";
            } else {
                this.state = "failed";
                this.error = j["detail"]
            }
        }
    },
    computed: {
        password_errors() {
            return validation.password_errors(this.password, this.password_verify);
        }
    },
    async mounted() {
        let res = await api.post(
            "/auth/check_reset_token",
            {
                "email": this.email,
                "token": this.token,
            }
        )
        let j = await res.json();

        if (res.status === 200){
            this.state = "reset";
        } else {
            this.state = "failed";
            this.error = j["detail"]
        }
    }
}
</script>
<style>
.reset-password-page {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 500px;
}
</style>
