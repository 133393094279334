<template>
<div class="header">
    <div v-if="!store.is_premium()" @click="$router.push({name: 'Premium'})">
    <b>Free Version</b>
    <div class="premium-button">&nbsp;Get Premium!&nbsp;</div>
    </div>
    <div v-else @click="$router.push({name: 'Premium'})">
      <div class="premium-button"><b>&nbsp;My Premium&nbsp;</b></div>
    </div>
    <div v-if="$route.name === 'Reports'" @click="logout" class="logout-button">Log Out</div>
</div>
<div class="post-header-offset"></div>

<div class="intro-banner-holder" v-if="definitelly_no_reports">
    <div class="intro-banner">
        <div class="heading">Welcome to PMcardio Digitize!</div>
        <p>Click the Upload New ECG button below to create your first report.</p>
    </div>
</div>
<div v-else class="reports_pane">
    <div v-for="report in reports" class="report" :key="report.id" @click="$router.push({
        name: 'Report',
        params: {report_id: report.report_id},
        query: {report_access_token: report.report_access_token, is_premium: report.is_premium},
    })">
        <div class="icon">
            <img class="format_pictogram" src="/img/formats/3x4x3.svg"/>
        </div>
        <div class="name_and_date">
            <div class="name">Report: {{report.name.slice(0, 8)}}</div>
            <div class="date">{{report.date}}</div>
        </div>
        <div class="icon">
            <img class="open_arrow" src="/img/other/arrow.svg"/>
        </div>
    </div>
</div>

<div class="new_report_holder">
    <div class="new_report" @click="new_report">
        + Upload New ECG
    </div>
</div>
</template>

<script>

import {store} from "../utils/store.js";
import {api} from "../utils/api.js"


export default {
    data() {
        return {
            page_count: 0,
            reports: [],
            store: store,
            definitelly_no_reports: false,
        }
    },
    methods: {
        new_report() {
            this.$router.push({name: 'NewReport'});
        },
        logout() {
            store.handle_logout();
            this.$router.push({name: "Login"});
        },
        async getNextBatch({ limit = 20, offset =   0 }) {
            let res = await api.auth_get(`/reports?limit=${limit}&offset=${offset}`);
            let response_json = await res.json();
            let new_reports = response_json["reports"];
            this.reports.push(...new_reports);

            if (new_reports.length > 0) {
                this.page_count++;
            } else if (offset === 0) {
                this.definitelly_no_reports = true;
            }

            store.handle_sub(response_json);
        },
        async handleScroll() {
            if (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight) {
                await this.getNextBatch({ limit: 20, offset: this.page_count * 20 });
            }
        }
    },

    async mounted() {
        await this.getNextBatch({ limit: 20 });
        window.onscroll = this.handleScroll
    },
}

</script>

<style>

.premium-button {
  display: inline-block;
  background-color: #B8EAD1;
  height: min(6vmin, 30px);
  line-height: min(6vmin, 30px);
  border-radius: min(2vmin, 10px);
  padding: 5px;
  margin-left: 1vmin;
}

.reports_pane {
    width: min(100vw, 720px);
    margin: 0 auto 0 auto;
}

.report {
    display: flex;
    border-radius: min(15px, 3vmin);
    margin: min(25px, 5vmin);
    height: max(40px, 3vh);
    padding: min(20px, 4vmin);
    background-color: #fff;
    gap: min(25px, 5vmin);
    cursor: pointer;
}
.report:hover {
    background-color: #e0e0e0;
}

.report .icon {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    height: 100%;
}

.report .icon .format_pictogram{
    height: 100%;
}

.report .icon .open_arrow{
    height: 40%;
}


.report .name_and_date {
    flex-grow: 1;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.report .name{
    display: flex;
    align-items: start;
    flex-basis: 100%;
    line-height: max(15px, 1.2vh);
    font-size: max(20px, 1.5vh);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
}
.report .date{
    display: flex;
    align-items: end;
    flex-basis: 100%;
    line-height: max(20px, 1.5vh);
    color: #888;
}


.new_report_holder {
    position: fixed;
    bottom: 5vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.new_report {
    background-color: #0033cc;
    border-radius: min(45px, 9vmin);
    color: white;
    font-size: min(25px, 5vmin);
    padding: min(20px, 4vmin);
    cursor: pointer;
}

.logout-button {
    margin-right: 1vmin;
    font-weight: bold;
}

.intro-banner-holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: 80vh;
}

.intro-banner {
    text-align: left;
    border-radius: 15px;
    background-color: white;
    padding: min(50px, 5vw);
    max-width: 80vw;
    box-shadow: 0 0 30px #eeeeee;
}

.intro-banner .heading {
    font-size: 2em;
    font-weight: bold;
}

</style>
