<template>
<div class="header">
    <div @click="$router.push({name: 'Reports'})">&lt; Back</div>
</div>
<div class="post-header-offset"></div>
<div id="report-view">
    <div class="report-title">Report {{ name }}</div>

    <img :src="report_source" id="report-image" @click="click_report" v-bind:style="report_click_to_premium_page ? 'cursor: pointer' : 'cursor: auto'"/>
    <div :class="store.is_premium() ? 'button blue-button' : 'button'" @click="share" v-if="can_share">{{ share_button_label }}</div>
    <div v-if="!store.is_premium()" class="button blue-button" @click="$router.push({name: 'Premium'})">Buy Premium</div>
</div>

</template>
<script>
import {api} from "../utils/api.js"
import { store } from "../utils/store";

export default {
    props: ["report_id", "report_access_token", "is_premium"],
    data() {
        return {
            name: this.report_id.slice(0, 8),
            report_path: `/report/${this.report_id}?report_access_token=${this.$route.query.report_access_token}`,
            can_share: false,
            generating_image: false,
            store: store,
        }
    },
    computed: {
        report_source() {
            return `${process.env.VUE_APP_API_URL}${this.report_path}`;
        },
        share_button_label() {
            if (this.generating_image) {
                return "Sharing...";
            } else {
                return "Share Report";
            }
        },
        report_click_to_premium_page() {
            return this.$route.query.is_premium === "false" && !store.is_premium();
        }
    },
    methods: {
        async share() {
            if (this.generating_image) return;
            this.generating_image = true;
            let res = await api.auth_get(this.report_path);
            let raw_image = await res.blob();
            const data = {
                files: [
                    new File([raw_image], `Report ${this.name}.png`, {
                        type: raw_image.type,
                    }),
                ],
            };
            await navigator.share(data);
            this.generating_image = false;
        },
        async click_report() {
            if (this.report_click_to_premium_page) {
                this.$router.push({name: "Premium"});
            }
        }
    },
    async mounted() {
        // This is a bit odd, but it gets the job done.
        // We check whether sharing a file is enabled by pretending to try with a text file:
        this.can_share = navigator.canShare({files: [new File([], "example.txt", {type: "application/text"})]});
    },
}

</script>
<style>

#report-view .button {
    width: min(70vmin, 70vh);
    margin: 10px auto 0 auto;
    text-align: center;
    padding: 5px;
    border: 1px solid #D3D5DB;
    border-radius: 5px;
    cursor: pointer;
}

.blue-button {
    background-color: #0033CC;
    color: #ffffff;
}

#report-image {
    display: block;
    max-width: min(70vw);
    max-height: min(70vh);
    margin: 0 auto 0 auto;
    box-shadow: 0 0 10px #aaaaaa;
}

#report-view .report-title {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

</style>